<template>
    <div class="container-form">
        <template v-if="loaded">
            <div class="form-tbf">
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.business-phone') }}</label>
                        <div v-if="errorsBe.business_phone" class="error-msg">{{ errorsBe.business_phone.join(" | ") }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-mobile /></div>
                        <div class="icon-right" v-if="business_phone != ''" @click.stop="business_phone = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.phone-ph')" class="input-text" v-model="business_phone">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.date-of-employment') }}</label>
                        <div v-if="errorsBe.date_of_employment" class="error-msg">{{ errorsBe.date_of_employment.join(" | ") }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-calendar /></div>
                        <div class="icon-right"  v-if="Object.keys(date_of_employment).length && date_of_employment.selectedDate" @click.stop="date_of_employment = {}"><icon-close class="icon-clear" /></div>
                        <FunctionalCalendar
                            ref="CalendarDateOfEmployment"
                            v-model="date_of_employment"
                            class="calendar-tbf"
                            :configs="calendarConfigs"
                        >
                        <template v-slot:datePickerInput="props">
                            <input
                            class="vfc-single-input custom-input-picker"
                            type="text"
                            :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                            v-if="Object.keys(date_of_employment).length"/>

                            <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-employment') }}</div>
                        </template>
                        </FunctionalCalendar>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.vacations-days') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-people /></div>
                        <div class="icon-right" v-if="vacation_days != ''" @click.stop="vacation_days = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.vacations-days-ph')" class="input-text" v-model="vacation_days">
                    </div>
                </div>
                
                <div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50']" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.job-function') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-briefcase /></div>
                        <div class="icon-right" v-if="job_function != ''" @click.stop="job_function = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.job-function-ph')" class="input-text" v-model="job_function">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.department') }}</label>
                        <div class="create-new" @click="showModal('department', {from: 'create-user'})">{{ $t('create-user.add-department') }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-department /></div>
                        <div class="icon-right" v-if="selectedDepartments.length" @click.stop="selectedDepartments = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                        class="hide-tags multiselect_checkbox"
                        v-bind:class="{populate: selectedDepartments.length}"
                        v-model="selectedDepartments"
                        :options="optionsDepartments"
                        :allow-empty="true"
                        :show-labels="false"
                        :multiple="true" 
                        :close-on-select="false"
                        track-by="id" 
                        label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-department') }}
                                </span>
                            </template>
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="checkbox">
                                    <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.functional_manager') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-people /></div>
                        <div class="icon-right" v-if="selectedFunctionalManager != ''" @click.stop="selectedFunctionalManager = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                        v-model="selectedFunctionalManager"
                        v-bind:class="{populate: selectedFunctionalManager != ''}"
                        :options="optionsUsers"
                        :allow-empty="true"
                        :show-labels="false"
                        track-by="id"
                        label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{ $t('create-user.choose_functional_manager') }}</span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.workstation') }}</label>
                        <div class="create-new" @click="showModal('workstation', {from: 'create-user'})">{{ $t('create-user.add-workstation') }}</div>
                        
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-building /></div>
                        <div class="icon-right" v-if="selectedWorkstation != ''" @click.stop="selectedWorkstation = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                        v-model="selectedWorkstation"
                        v-bind:class="{populate: selectedWorkstation != ''}"
                        :options="optionsWorkstations"
                        :allow-empty="true"
                        :show-labels="false"
                        track-by="id"
                        label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-workstation') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{$t('profile.recurrence_evaluations')}}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-gears /></div>
                        <div class="icon-right" v-if="selectedRecurrenceEvaluation" @click.stop="selectedRecurrenceEvaluation = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                        v-bind:class="{populate: selectedRecurrenceEvaluation != ''}"
                        v-model="selectedRecurrenceEvaluation"
                        :options="optionsRecurrenceEvaluation"
                        :allow-empty="true"
                        :show-labels="false"
                        :multiple="false" 
                        :close-on-select="true"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{$t('profile.choose_recurrence_evaluation')}}</span>
                            </template>
                            <template slot="option" slot-scope="props">{{ $t(`profile.recurrence_evaluations_list.${props.option}`) }}</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ $t(`profile.recurrence_evaluations_list.${option}`) }}</template>
                            <template slot="noResult">{{ $t('general.no_result') }}</template>
                            <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group" :class="[ $resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50' ]" v-if="soft_objectives && soft_objectives.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.promise_day') }}</label>
                        <div v-if="$v.promise_time.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    </div>

                    <div class="input-box" v-bind:class="{has_error: $v.promise_time.$error}">
                        <div class="icon-left"><icon-clock /></div>
                        <multiselect 
                        v-model="promise_time"
                        :options="optionsHours"
                        :allow-empty="false"
                        :show-labels="false"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{ $t('create-user.choose-hour') }}</span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'" v-if="soft_organigram && soft_organigram.is_active">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.work-schedule') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-work-schedule /></div>
                        <multiselect 
                            v-model="selectedWorkSchedule"
                            :options="optionsWorkSchedules"
                            :allow-empty="false"
                            :show-labels="false"
                            track-by="id"
                            label="name"
                            @select="changeWorkSchedule"
                            >

                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-template') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="info-form" v-if="selectedWorkSchedule != ''">
                    <div class="info-work-schedule" v-bind:class="{has_error: $v.working_days.$error}">
                        <div class="header">
                            <div class="title-template">{{ selectedWorkSchedule.name }}</div>
                            <div class="error-msg" v-if="$v.working_days.$error">{{ $t('validator.required') }}</div>
                        </div>
                        <!-- <div class="intervals" v-if="selectedWorkSchedule.name != 'Custom' && selectedWorkSchedule.id != user.work_schedule_id">
                            <template v-for="workingDay in working_days_data">
                                <div class="interval">
                                    <div class="day" v-bind:class="{clicked: selectedWorkSchedule.schedules.find(el => el.day == workingDay) || working_days.includes(workingDay)}">{{ $t(`general.${workingDay}`) }}</div>
                                    <div class="box">
                                        <div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == workingDay)">{{ selectedWorkSchedule.schedules.find(el => el.day == workingDay).start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == workingDay).end_time }}</div>
                                        <div class="value" v-else>{{ $t('general.undefined') }}</div>
                                    </div>
                                </div>
                            </template>
                        </div> -->

                        <!-- <div class="intervals clickable" :class="{hidden: selectedWorkSchedule.name != 'Custom' && selectedWorkSchedule.id != user.work_schedule_id}"> -->
                        <div class="intervals clickable">
                            <template v-for="workingDay in working_days_data">
                                <div class="interval" v-bind:class="{has_error: $v.customSchedule[workingDay].start.$error || $v.customSchedule[workingDay].end.$error}">
                                    <div class="day" :class="{clicked: working_days.includes(workingDay)}" @click="selectWorkingDay(workingDay)">{{ $t(`general.${workingDay}`) }}</div>
                                   
                                    <div class="box" :class="{disabled: !working_days.includes(workingDay), hidden: soft_organigram && !soft_organigram.is_active }">
                                        <div class="interval-inputs">
                                            <div class="start-hour" v-bind:class="{has_error: $v.customSchedule[workingDay].start.$error}">
                                                <input v-model="customSchedule[workingDay].start" :id="`timeInput-${workingDay}-start`" type="text" class="time-picker" :placeholder="$t('general.start')" :disabled="!working_days.includes(workingDay)"/>
                                            </div>
                                            <div v-bind:class="{has_error: $v.customSchedule[workingDay].end.$error}">
                                                <input v-model="customSchedule[workingDay].end" :id="`timeInput-${workingDay}-end`" type="text" class="time-picker" :placeholder="$t('general.end')" :disabled="!working_days.includes(workingDay)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-submit">
                <div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
                <div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>
    
                <div class="actions-steps">
                    <button class="btn-tbf white only-icon prev" @click="changeStepFunction('prevStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                    <button class="btn-tbf white only-icon" @click="changeStepFunction('nextStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                </div>
    
                <div class="action-form">
                    <button id="buttonSubmit" class="btn-tbf blue" @click="saveAction()">
                        <div class="loader-spin" v-if="loadedSubmit"></div>
                        <span class="text" v-else>{{ $t('general.update') }}</span>
                    </button>
                </div>
            </div>
        </template>
        <LoaderStepJobDetails v-else />
    </div>
</template>

<script>
import IconPeople from '@/components/Icons/User'
import IconClose from '@/components/Icons/Close'
import IconArrow from '@/components/Icons/AngleRight'
import IconBriefcase from '@/components/Icons/Briefcase'
import IconDepartment from '@/components/Icons/Department'
import IconBuilding from '@/components/Icons/Building'
import IconWorkSchedule from '@/components/Icons/WorkSchedule'
import IconGears from '@/components/Icons/Gears'
import IconCalendar from '@/components/Icons/Calendar'
import IconClock from '@/components/Icons/Clock'
import LoaderStepJobDetails from '@/components/PagesLoaders/CreateUserStepJobDetails'
import IconMobile from '@/components/Icons/Mobile'

import { required, requiredIf } from 'vuelidate/lib/validators'
import timepicker from 'timepicker';

export default {
    components: {
        IconClose,
        IconPeople,
        IconMobile,
        IconArrow,
        IconBriefcase,
        IconDepartment,
        IconBuilding,
        IconWorkSchedule,
        IconGears,
        IconCalendar,
        IconClock,
        LoaderStepJobDetails
    },
    props: {
        user: Object
    },
    computed: {
        soft_procedures() {
            return this.$store.getters['applications/getApplication']('Proceduri');
        },
        soft_objectives() {
            return this.$store.getters['applications/getApplication']('Obiective');
        },
        soft_organigram() {
            return this.$store.getters['applications/getApplication']('Organigrama');
        }
    },
    data() {
        return {
            loaded: false,
            loadedSubmit: false,
            error_message: '',
            success_message: '',
            errorsBe: [],
            initialData: '',
            business_phone: '',
            date_of_employment: {},
            vacation_days: '',
            job_function: '',
            optionsDepartments: [],
            selectedDepartments: [],
            optionsUsers: [],
            selectedFunctionalManager: '',
            optionsWorkstations: [],
            selectedWorkstation: '',
            optionsWorkSchedules: [],
            selectedWorkSchedule: '',
            customSchedule: {
                monday: {start: '', end: ''},
                tuesday: {start: '', end: ''},
                wednesday: {start: '', end: ''},
                thursday: {start: '', end: ''},
                friday: {start: '', end: ''},
                saturday: {start: '', end: ''},
                sunday: {start: '', end: ''}
            },
            selectedRecurrenceEvaluation: '',
            optionsRecurrenceEvaluation: [ 'bi_monthly', 'monthly', 'quarterly' ],
            working_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
            working_days_data: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            defaultWorkingDays: [],
            promise_time: '10:00',
            optionsHours: [],
            calendarConfigs: {
                dateFormat: 'yyyy-mm-dd',
                isDatePicker: true,
                isDateRange: false,
                isModal: true,
                isAutoCloseable: true,
                changeMonthFunction: true,
                changeYearFunction: true
            },
            timepickerKey: 1,
        }
    },
    validations: {
        customSchedule: {
            monday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.monday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.monday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            tuesday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.tuesday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.tuesday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            wednesday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.wednesday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.wednesday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            thursday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.thursday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.thursday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            friday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.friday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.friday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            saturday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.saturday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.saturday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            },
            sunday: {
                start: { required: requiredIf(function (model) {
                    return this.customSchedule.sunday.end && this.soft_organigram && this.soft_organigram.is_active
                })},
                end: { required: requiredIf(function (model) {
                    return this.customSchedule.sunday.start && this.soft_organigram && this.soft_organigram.is_active
                })}
            }
        },
        promise_time: { required: requiredIf(function () {
            return this.soft_objectives && this.soft_objectives.is_active;
        }) },
        working_days: {required}
    },
    beforeDestroy() {
        this.$root.$off("user_form_change_step");
        this.$root.$off("addDropdownUserCreate");
    },
    async mounted() {
        this.generateHours();
        await this.getFilters();
        
        if( this.soft_objectives && this.soft_objectives.is_active && this.$route.params.slug) {
            await this.getUserDataObjective();
        } else {
            await this.populateUserData();
        }
        // await this.populateUserData();

        this.$root.$on('user_form_change_step', (modeSave, changeData) => {
            if(modeSave == 'save') {
                this.saveAction(changeData);
            } else {
                if(!changeData.step) {
                    this.$emit(changeData.direction);
                } else {
                    this.$emit('goToStep', changeData.step)
                }
            }
        });

        this.$root.$on('addDropdownUserCreate', (data, type) => {
            switch (type) {
                case 'workstation':
                    this.optionsWorkstations.push(data)
                    this.selectedWorkstation = data
                    break;
                case 'department':
                    this.optionsDepartments.push(data)
                    this.selectedDepartments.push(data)
                    break;
            }
        })

    },
    methods: {
        async getFilters(){
            var filtersDB = {
                departments: true, 
                users: true,
                workstations: true, 
                work_schedules: this.user.slug ? this.user.slug : 'roles',
            }

            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
            .then(({data}) => {
                this.optionsDepartments = data.data.departments;
                this.optionsUsers = data.data.users;
                this.optionsWorkstations = data.data.workstations;
                this.optionsWorkSchedules = data.data.work_schedules;
                
                if( !this.optionsWorkSchedules.find(el => el.custom_user_id) ) {
                    this.optionsWorkSchedules.push({name: 'Custom'});
                }
            }).catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
        },
        async getUserDataObjective() {
            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$route.params.slug}/edit`)
            .then(({data}) => {
                this.working_days = data.data.working_days;
                this.defaultWorkingDays = data.data.working_days;
                this.promise_time = data.data.promise_time;
            })
            .finally(() => {
                this.populateUserData();
            })
        },
        showModal(type, data = false){
            this.$root.$emit('open_modal', type, data);
        },
        selectWorkingDay(day){
            if ( this.working_days.includes(day) ) {
                this.working_days.splice(this.working_days.indexOf(day), 1)
                this.customSchedule[day].start = '';
                this.customSchedule[day].end = '';
            } else {
                this.working_days.push(day)
                this.customSchedule[day].start = '09:00';
                this.customSchedule[day].end = '17:00';
            }
        },
        toggleTimeInputs() {
            Object.keys(this.customSchedule).map(el => {
                Object.keys(this.customSchedule[el]).map(el2 => {
                    $(`#timeInput-${el}-${el2}`).timepicker({ 
                        'scrollDefault': 'now', 
                        'timeFormat': 'H:i', 
                        'show2400': false, 
                        'step': '15' 
                    }).on('changeTime', () => {
                        this.customSchedule[el][el2] =  $(`#timeInput-${el}-${el2}`).val()
                    })
                })
            })
        },
        populateUserData() {
            if(this.user.date_of_employment){
                this.date_of_employment = {
                    selectedDate: this.user.date_of_employment,
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: '',start: ''}
                }
            }

            this.business_phone = this.user.business_phone ? this.user.business_phone : '';
            this.job_function = this.user.job_function ? this.user.job_function : '';
            this.vacation_days = this.user.vacation_days ? this.user.vacation_days : '';
            this.selectedRecurrenceEvaluation = this.user.evaluation_frequency ? this.user.evaluation_frequency : '';
            this.selectedWorkstation = this.user.workstation_id ? this.optionsWorkstations.find(el => el.id == this.user.workstation_id) : '';
            this.selectedFunctionalManager = this.user.parent_function_id ? this.optionsUsers.find(el => el.id == this.user.parent_function_id) : '';
            this.selectedWorkSchedule = this.user.work_schedule_id ? ( this.optionsWorkSchedules.find(el => el.id == this.user.work_schedule_id) ? this.optionsWorkSchedules.find(el => el.id == this.user.work_schedule_id) : { name: 'Custom' } )  : { name: 'Custom' };
            // Generate custom Schedule for the selected Schedule
            this.changeWorkSchedule(this.selectedWorkSchedule, true);
            
            if(this.user.departments.length){
                this.user.departments.map((department) => {
                    this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == department))
                })
            }

            if(this.$route.query.department_id){
				this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == this.$route.query.department_id))
			}
            
            this.$nextTick(() => {
                this.loaded = true;

                setTimeout(() => {
                    this.toggleTimeInputs();
                }, 500)

                this.initialData = JSON.stringify({
                    business_phone: this.business_phone,
                    date_of_employment: Object.keys(this.date_of_employment).length && this.date_of_employment.selectedDate ? this.date_of_employment.selectedDate : '',
                    job_function: this.job_function,
                    vacation_days: this.vacation_days,
                    selectedRecurrenceEvaluation: this.selectedRecurrenceEvaluation,
                    selectedWorkstation: this.user.workstation_id ? this.user.workstation_id : '',
                    selectedWorkSchedule: this.user.work_schedule_id ? this.user.work_schedule_id : ( this.selectedWorkSchedule.name == 'Custom' ? this.generateSchedule() : this.selectedWorkSchedule.id),
                    selectedFunctionalManager: this.user.parent_function_id ? this.user.parent_function_id : '',
                    departments: this.user.departments,
                    promise_time: this.promise_time,
                    working_days: this.working_days,
                    schedules: this.selectedWorkSchedule.hasOwnProperty('id') ? this.selectedWorkSchedule.schedules.map(el => { return { day: el.day, start_time: el.start_time, end_time: el.end_time }; }) : this.generateSchedule().schedules
                });
            });
        },
        async saveAction(changeStep = false, withRedirect = false) {
            var btnSubmit = document.getElementById('buttonSubmit');
            btnSubmit.disabled = true;
            this.loadedSubmit = true;

            this.error_message = '';

            this.$v.$touch()
            if(!this.$v.$invalid){
                // Object form data for organigram
                let objDataORG = {};
                objDataORG.business_phone = this.business_phone;
                objDataORG.date_of_employment = Object.keys(this.date_of_employment).length ? this.date_of_employment.selectedDate : '';
                objDataORG.vacation_days = this.vacation_days
                objDataORG.job_function = this.job_function;
                objDataORG.departments = this.selectedDepartments.length ? this.selectedDepartments.map(function(a) {return a.id;}) : [];
                objDataORG.parent_function_id = this.selectedFunctionalManager ? this.selectedFunctionalManager.id : ''
                objDataORG.workstation_id = this.selectedWorkstation ? this.selectedWorkstation.id : ''
                objDataORG.evaluation_frequency = this.selectedRecurrenceEvaluation;

                var initialSchedules = JSON.parse(this.initialData).schedules;
                var currentSchedules = this.generateSchedule().schedules;

                if(JSON.stringify(initialSchedules) == JSON.stringify(currentSchedules)){
                    objDataORG.work_schedules = this.selectedWorkSchedule.hasOwnProperty('id') ? {id: this.selectedWorkSchedule.id} : this.generateSchedule();
                } else {
                    objDataORG.work_schedules = this.generateSchedule();
                }

                // Object form data for objectiv
                let objDataOBT = {};
                objDataOBT.promise_time = this.promise_time;
                objDataOBT.working_days = this.working_days;

                if( this.soft_objectives && this.soft_objectives.is_active ) {
                    this.updateUserObj(objDataOBT, changeStep, withRedirect)
                }

                if(this.soft_organigram && this.soft_organigram.is_active) {
                    this.updateUserOrg(objDataORG, changeStep, withRedirect)
                }
            } else {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;
            }
        },
        updateUserOrg(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.patch(`/users/${this.user.slug}`, objData)
            .then(({data}) => {
                this.$parent.userData = data.data;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                this.success_message = this.$t('create-user.success_update_msg');
                setTimeout(() => {
                    this.success_message = '';
                }, 3000);

                axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: { work_schedules: this.user.slug } })
                .then(({data}) => {
                    this.optionsWorkSchedules = data.data.work_schedules;
                    
                    if( !this.optionsWorkSchedules.find(el => el.custom_user_id) ) {
                        this.optionsWorkSchedules.push({name: 'Custom'});
                    }

                    this.selectedWorkSchedule = this.user.work_schedule_id ? ( this.optionsWorkSchedules.find(el => el.id == this.user.work_schedule_id) ? this.optionsWorkSchedules.find(el => el.id == this.user.work_schedule_id) : { name: 'Custom' } )  : { name: 'Custom' };
                    this.changeWorkSchedule(this.selectedWorkSchedule, true);
                });

                this.changeStepOrRedirect(changeStep, withRedirect, data.data.slug);
            })
            .catch(error => {
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 500){
                        this.error_message = this.$t('error.500')
                    }
                }, 300)
            })
            .finally(() =>  {
                this.initialData = JSON.stringify({
                    business_phone: this.business_phone,
                    date_of_employment: Object.keys(this.date_of_employment).length && this.date_of_employment.selectedDate ? this.date_of_employment.selectedDate : '',
                    job_function: this.job_function,
                    vacation_days: this.vacation_days,
                    selectedRecurrenceEvaluation: this.selectedRecurrenceEvaluation,
                    selectedWorkstation: this.user.workstation_id ? this.user.workstation_id : '',
                    selectedWorkSchedule: this.user.work_schedule_id ? this.user.work_schedule_id : '',
                    selectedFunctionalManager: this.user.parent_function_id ? this.user.parent_function_id : '',
                    departments: this.user.departments,
                    promise_time: this.promise_time,
                    working_days: this.working_days,
                    schedules: this.selectedWorkSchedule.hasOwnProperty('id') ? this.selectedWorkSchedule.schedules.map(el => { return { day: el.day, start_time: el.start_time, end_time: el.end_time }; }) : this.generateSchedule().schedules
                });
            });
        },
        updateUserObj(objData, changeStep, withRedirect) {
            axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.user.slug}`, objData)
            .then(({data}) => {
                if(this.soft_organigram && !this.soft_organigram.is_active) {
                    this.loadedSubmit = false;

                    var btnSubmit = document.getElementById('buttonSubmit');
                    btnSubmit.disabled = false;

                    this.success_message = this.$t('create-user.success_update_msg');
                    setTimeout(() => {
                        this.success_message = '';
                    }, 3000);
                    
                    this.changeStepOrRedirect(changeStep, withRedirect, this.$route.params.slug);
                }
            })
            .finally(() =>  {
                this.defaultWorkingDays = this.working_days;

                if(this.soft_organigram && !this.soft_organigram.is_active) {
                    this.initialData = JSON.stringify({
                        business_phone: this.business_phone,
                        date_of_employment: Object.keys(this.date_of_employment).length && this.date_of_employment.selectedDate ? this.date_of_employment.selectedDate : '',
                        job_function: this.job_function,
                        vacation_days: this.vacation_days,
                        selectedRecurrenceEvaluation: this.selectedRecurrenceEvaluation,
                        selectedWorkstation: this.user.workstation_id ? this.user.workstation_id : '',
                        selectedWorkSchedule: this.selectedWorkSchedule ? (this.selectedWorkSchedule.name == 'Custom' ? this.generateSchedule() : this.selectedWorkSchedule.id ) : '',
                        selectedFunctionalManager: this.user.parent_function_id ? this.user.parent_function_id : '',
                        departments: this.user.departments,
                        promise_time: this.promise_time,
                        working_days: this.working_days,
                        schedules: this.selectedWorkSchedule.hasOwnProperty('id') ? this.selectedWorkSchedule.schedules.map(el => { return { day: el.day, start_time: el.start_time, end_time: el.end_time }; }) : this.generateSchedule().schedules
                    });
                }
            });
        },
        changeStepFunction(direction, stepNo = false) {
            // Create a JSon stringify with current data form
            var currentObject = JSON.stringify({
                business_phone: this.business_phone,
                date_of_employment: Object.keys(this.date_of_employment).length && this.date_of_employment.selectedDate ? this.date_of_employment.selectedDate : '',
                job_function: this.job_function,
                vacation_days: this.vacation_days,
                selectedRecurrenceEvaluation: this.selectedRecurrenceEvaluation,
                selectedWorkstation: this.selectedWorkstation ? this.selectedWorkstation.id : '',
                selectedWorkSchedule: this.selectedWorkSchedule ? (this.selectedWorkSchedule.name == 'Custom' ? this.generateSchedule() : this.selectedWorkSchedule.id ) : '',
                selectedFunctionalManager: this.selectedFunctionalManager ? this.selectedFunctionalManager.id : '',
                departments: this.selectedDepartments.length ? this.selectedDepartments.map(function(a) {return a.id;}) : [],
                promise_time: this.promise_time,
                working_days: this.working_days,
                schedules: this.selectedWorkSchedule.hasOwnProperty('id') ? this.selectedWorkSchedule.schedules.map(el => { return { day: el.day, start_time: el.start_time, end_time: el.end_time }; }) : this.generateSchedule().schedules
            });

            // Check diference between initial data and current
            if(this.initialData === currentObject || !this.loaded) {
                if(direction) { 
                    this.$emit(direction);
                } else if(stepNo) {
                    this.$emit('goToStep', stepNo)
                }
            } else {
                this.$root.$emit('open_modal', 'confirm_change_step', { direction: direction, step: stepNo });
            }
        },
        generateHours(){
            var MinuteInterval = 60;
            var o, n, i, g, r, S, l;

            o = ['00:00'], n = 0, i = 0, r = 0, S = 0, g = 0, l = "AM";
            for (var e = 1; e < 60 / MinuteInterval * 24; e++) 60 == (i += MinuteInterval) && (g = n += 1, n >= 12 && (l = "PM"), i = 0), r = n > 12 ? n - 12 : n, 0 == n && (r = 12), 1 == n.toString().length && (g = "0" + n.toString(), g = "0" + n.toString()), 1 == r.toString().length && (r = "0" + r.toString()), S = 1 == i.toString().length ? "0" + i.toString() : i, o.push((g.toString() + ":" + S.toString()));

            this.optionsHours = o
        },
        generateSchedule(){
            var schedules = []

            if(this.customSchedule.monday.start && this.customSchedule.monday.end){
                var mondayObj = {day: 'monday', start_time: this.customSchedule.monday.start, end_time: this.customSchedule.monday.end}
                schedules.push(mondayObj)
            }
            if(this.customSchedule.tuesday.start && this.customSchedule.tuesday.end){
                var tuesdayObj = {day: 'tuesday', start_time: this.customSchedule.tuesday.start, end_time: this.customSchedule.tuesday.end}
                schedules.push(tuesdayObj)
            }
            if(this.customSchedule.wednesday.start && this.customSchedule.wednesday.end){
                var wednesdayObj = {day: 'wednesday', start_time: this.customSchedule.wednesday.start, end_time: this.customSchedule.wednesday.end}
                schedules.push(wednesdayObj)
            }
            if(this.customSchedule.thursday.start && this.customSchedule.thursday.end){
                var thursdayObj = {day: 'thursday', start_time: this.customSchedule.thursday.start, end_time: this.customSchedule.thursday.end}
                schedules.push(thursdayObj)
            }
            if(this.customSchedule.friday.start && this.customSchedule.friday.end){
                var fridayObj = {day: 'friday', start_time: this.customSchedule.friday.start, end_time: this.customSchedule.friday.end}
                schedules.push(fridayObj)
            }
            if(this.customSchedule.saturday.start && this.customSchedule.saturday.end){
                var saturdayObj = {day: 'saturday', start_time: this.customSchedule.saturday.start, end_time: this.customSchedule.saturday.end}
                schedules.push(saturdayObj)
            }
            if(this.customSchedule.sunday.start && this.customSchedule.sunday.end){
                var sundayObj = {day: 'sunday', start_time: this.customSchedule.sunday.start, end_time: this.customSchedule.sunday.end}
                schedules.push(sundayObj)
            }

            var objCustomSchedule = {
                name: `Custom ${this.user.first_name} ${this.user.last_name}`,
                schedules: schedules
            }

            return objCustomSchedule
        },
        changeStepOrRedirect(changeStep, withRedirect, userSlug) {
            if(changeStep) {
                this.$nextTick(() => {
                    if(!changeStep.step) {
                        this.$emit(changeStep.direction);
                    } else {
                        this.$emit('goToStep', changeStep.step)
                    }
                });
            }

            if(withRedirect) {
                if(this.$route.query.department_id){
                    this.$router.push({name: 'company'})
                }else{
                    this.$router.push({name: 'user-show', params: { slug: userSlug }})
                }
            }
        },
        changeWorkSchedule(value, fromMounted = false) {
            this.working_days = [];
            
            if(typeof value === 'object' && value.hasOwnProperty('id') && this.soft_organigram && this.soft_organigram.is_active) {
                if(!fromMounted) { 
                    var oldInitialData = JSON.parse(this.initialData);
                    this.initialData = JSON.stringify({...oldInitialData, schedules: value.schedules.map(el => { return { day: el.day, start_time: el.start_time, end_time: el.end_time }; })});
                }

                this.working_days_data.map(day => {
                    var scheduleFound = value.schedules.find(el => el.day == day);
                    if(scheduleFound) {
                        this.customSchedule[day].start = scheduleFound.start_time;
                        this.customSchedule[day].end = scheduleFound.end_time;
                        this.working_days.push(day);
                    } else {
                        this.customSchedule[day].start = '';
                        this.customSchedule[day].end = '';
                    }
                })
            } else {
                this.working_days = [...this.defaultWorkingDays];
                this.customSchedule = {
                    monday: {start: '', end: ''},
                    tuesday: {start: '', end: ''},
                    wednesday: {start: '', end: ''},
                    thursday: {start: '', end: ''},
                    friday: {start: '', end: ''},
                    saturday: {start: '', end: ''},
                    sunday: {start: '', end: ''}
                };

                this.working_days_data.map(day => {
                    if(this.working_days.find(el => el == day)) {
                        this.customSchedule[day].start = '09:00';
                        this.customSchedule[day].end = '17:00';
                    } else {
                        this.customSchedule[day].start = '';
                        this.customSchedule[day].end = '';
                    }
                })

            }
        }
    }
}
</script>